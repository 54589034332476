.map-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.map {
    height: 100%;
}

.map-fog {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 23%;
    width: 100%;
    background: linear-gradient(var(--color-background-primary) 10%, rgba(0, 0, 0, 0));
}

.marker {
    display: flex;
    justify-content: center;
    position: relative;
}

.marker-img {
    position: absolute;
    top: -50px;
}

.marker-fade-in {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.marker-tooltip {
    padding: 5px;
    position: absolute;
    display: none;
    text-wrap: nowrap;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
