.map-wrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.map {
  height: 100%;
}

.map-fog {
  z-index: 1;
  background: linear-gradient(var(--color-background-primary) 10%, #0000);
  width: 100%;
  height: 23%;
  position: absolute;
  top: 0;
}

.marker {
  justify-content: center;
  display: flex;
  position: relative;
}

.marker-img {
  position: absolute;
  top: -50px;
}

.marker-fade-in {
  opacity: 0;
  animation: .5s forwards fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.marker-tooltip {
  text-wrap: nowrap;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  display: none;
  position: absolute;
  box-shadow: 0 2px 5px #0000004d;
}
/*# sourceMappingURL=index.c7ad19ea.css.map */
